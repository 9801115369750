<template>
    <div>
        <CRow>
            <CCol>
                <CCard class="tarjetaGesto">
                    <CCardHeader>
                        <h4 class="tituloTarjeta">Balance</h4>
                    </CCardHeader>
                    
                    <CCardBody>
                        <CRow>
                            <CCol md="4">
                                Seleccionar último período
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    :checked="selectPeriodoActual"
                                    @update:checked="seleccionarPeriodoActual()"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="2">
                                <multiselect
                                    class="mb-2 multSelectGesto"
                                    :custom-label="customLabelEmp"
                                    v-model="empSelected"
                                    selectedLabel=""
                                    deselectLabel=""
                                    selectLabel=""
                                    placeholder="Seleccionar Empresa" 
                                    label="nombreEmpresa"
                                    track-by="codEmpresa"
                                    :options="empresasInfo"
                                    :multiple="false"
                                    @input="seleccionarEmpresa"></multiselect>
                            </CCol>
                            <CCol md="2">
                                <date-picker
                                    style="width: 100%"
                                    class="mb-2"
                                    :editable="false"
                                    :clearable="false"
                                    :format="'YYYY'"
                                    default-panel="year"
                                    type="year"
                                    :lang="langPicker" 
                                    v-model="anioPeriodo"
                                    :confirm="false"
                                    :disabled-date="deshabilitarPeriodos"
                                    @change="selectRangoAnio"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: (empSelected != null)?'Seleccionar periodo de consulta':'Seleccione una empresa para ver periodos'
                                    }">
                                </date-picker>
                            </CCol>
                            <CCol md="2">
                                <date-picker
                                    style="width: 100%"
                                    class="mb-2"
                                    :editable="false"
                                    :format="'MMMM'"
                                    default-panel="month"
                                    type="month"
                                    :lang="langPicker"
                                    v-model="mesesPeriodo"
                                    :confirm="false"
                                    range
                                    :disabled-date="deshabilitarRangoMeses"
                                    @change="selectRangoMes"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: (empSelected != null)?'Seleccionar mes o meses de consulta':'Seleccione una empresa para ver periodos'
                                    }"
                                ></date-picker>
                            </CCol>
                            <CCol md="2" v-show="comparativo">
                                <date-picker
                                    style="width: 100%"
                                    :editable="false"
                                    :clearable="false"
                                    :format="'YYYY'"
                                    default-panel="year"
                                    type="year"
                                    :lang="langPicker" 
                                    v-model="anioComparar"
                                    :confirm="false"
                                    :disabled-date="deshabilitarPeriodos2"
                                    @change="selectRangoAnioComparar"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: (empSelected != null)?`Seleccionar Año a comparar contra ${(this.anioSelected!=null)?this.anioSelected.anio:''}`:'Seleccione una empresa para ver periodos'
                                    }">
                                </date-picker>
                            </CCol>
                            <CCol md="2">
                                Comparativo <CSwitch
                                    width="150px"
                                    class="mt-1 mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                    :checked="comparativo"
                                    @update:checked="comparativo = !comparativo; cambiarComparativo(comparativo)"
                                />
                                <!--
                                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                    v-bind="{labelOn: 'Si',labelOff: 'no'}"
                                    -->
                            </CCol>
                            
                            <!--<CCol md="2">

                                <CButton
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1"
                                    @click="generarPdf"
                                    :disabled="isLoadingPdf"
                                    v-c-tooltip.hover="{content: 'Descargar PDF',placement: 'over'}">
                                        <CIcon v-if="isLoadingPdf" :content="$options.freeSet.cilCloudDownload" class="imgr" />
                                        <CIcon v-else :content="$options.freeSet.cilCloudDownload"  />
                                        PDF
                                </CButton>
                                <CButton
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1 ml-1"
                                    @click="generarCsv"
                                    :disabled="isLoadingCsv"
                                    v-c-tooltip.hover="{content: 'Descargar CSV',placement: 'over'}">
                                        <CIcon v-if="isLoadingCsv" :content="$options.freeSet.cilCloudDownload" class="imgr"  />
                                        <CIcon v-else :content="$options.freeSet.cilCloudDownload" />
                                        CSV
                                </CButton>
                            </CCol>-->
                            
                        </CRow>
                        <CRow>
                            <CCol md="1">
                                <CButton
                                    size="sm"
                                    v-show="mesDesdeSelected != null && mesHastaSelected != null && anioSelected != null && empSelected != null && ((!comparativo && (anio2Selected == null || anio2Selected != null)) || (comparativo && anio2Selected != null)) && balance.length>0"
                                    variant="ghost"
                                    class="btnPrincipalVapp mb-1 mr-1"
                                    @click="verModalDocumentos = true"
                                    :disabled="isLoadingPdf"
                                    v-tippy="{ 
                                        placement: 'top',
                                        arrow: true, 
                                        arrowType: 'round', 
                                        animation:'fade',
                                        theme: 'google',
                                        followCursor : true,
                                        content: 'Generar Documento'
                                    }">
                                        <CIcon v-show="isLoadingPdf" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                        Generar Documento
                                </CButton>
                                
                            </CCol>
                            <CCol md="3" v-show="balance.length>0">
                                Ocultar valores en Ceros
                                <CSwitch
                                    width="150px"
                                    class="mx-1 align-middle mb-2 switchGesto"
                                    shape="pill"
                                    variant="outline"
                                    v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                    :checked="ocultarCeros"
                                    @update:checked="ocultarCerosProcesar"
                                />
                            </CCol>
                        </CRow>
                        
                        <CargandoInfo :mostrarCarga="isLoadingBalance" :mensajeInfo="'Obteniendo Información'" />

                        <CRow style="overflow-x: scroll; height: 500px;">
                            <CCol col="12">
                                <table class="tableGesto mt-2" v-show="!isLoadingBalance && totales != null">
                                    <thead>
                                        <th v-for="col in columnasBalance" v-bind:key="col.scopedSlots.customRender" :style="{'text-align': col.align}">{{col.title}}</th>
                                    </thead>
                                    <tbody>
                                        <tr v-show="!comparativo" v-for="bl in balance" v-bind:key="bl.codigo+1">
                                            <td>{{bl.codigo}}</td>
                                            <td style="text-align: right;">{{bl.descripcion}}</td>
                                            <td style="text-align: right;">{{bl.debito | currency}}</td>
                                            <td style="text-align:right;">{{bl.credito | currency}}</td>
                                            <td style="text-align:right;">{{bl.deudor | currency}}</td>
                                            <td style="text-align:right">{{bl.acreedor | currency}}</td>
                                            <td style="text-align:right">{{bl.activo | currency}}</td>
                                            <td style="text-align:right">{{bl.pasivo | currency}}</td>
                                            <td style="text-align:right">{{bl.perdida | currency}}</td>
                                            <td style="text-align:right">{{bl.ganancia | currency}}</td>

                                        </tr>
                                        <tr v-show="comparativo" v-for="bl in balance" v-bind:key="bl.codigo">
                                            <td>{{bl.codigo}}</td>
                                            <td style="text-align:right;">{{bl.descripcion}}</td>
                                            <td style="text-align:right;" >{{bl.debito | currency}}</td>
                                            <td style="text-align:right;" >{{bl.debito2 | currency}}</td>
                                            <td style="text-align:right;">{{bl.credito | currency}}</td>
                                            <td style="text-align:right;">{{bl.credito2 | currency}}</td>
                                            <td style="text-align:right;">{{bl.deudor | currency}}</td>
                                            <td style="text-align:right;">{{bl.deudor2 | currency}}</td>
                                            <td style="text-align:right">{{bl.acreedor | currency}}</td>
                                            <td style="text-align:right">{{bl.acreedor2 | currency}}</td>
                                            <td style="text-align:right">{{bl.activo | currency}}</td>
                                            <td style="text-align:right">{{bl.activo2 | currency}}</td>
                                            <td style="text-align:right">{{bl.pasivo | currency}}</td>
                                            <td style="text-align:right">{{bl.pasivo2 | currency}}</td>
                                            <td style="text-align:right">{{bl.perdida | currency}}</td>
                                            <td style="text-align:right">{{bl.perdida2 | currency}}</td>
                                            <td style="text-align:right">{{bl.ganancia | currency}}</td>
                                            <td style="text-align:right">{{bl.ganancia2 | currency}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>

                                        <tr v-show="totales != null && comparativo">
                                            <td></td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.descripcion:"-"}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totGeneral.debito:"0" | currency}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totGeneral.debito2:"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.credito:"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.credito2:"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.deudor:"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.deudor2:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.acreedor:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.acreedor2:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.activo:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.activo2:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.pasivo:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.pasivo2:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.perdida:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.perdida2:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.ganancia:"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.ganancia2:"0" | currency}}</td>
                                        </tr>
                                        <tr v-show="totales != null && comparativo">
                                            <td></td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totRes.descripcion:"-"}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totRes.debito  :"0" | currency}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totRes.debito2 :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totRes.credito :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totRes.credito2 :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totRes.deudor :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totRes.deudor2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.acreedor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.acreedor2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.activo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.activo2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.pasivo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.pasivo2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.perdida :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.perdida2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.ganancia :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.ganancia2 :"0" | currency}}</td>
                                        </tr>
                                        <tr v-show="totales != null && comparativo">
                                            <td></td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totSum.descripcion:"-"}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totSum.debito :"0" | currency}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totSum.debito2 :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totSum.credito :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totSum.credito2 :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totSum.deudor :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totSum.deudor2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.acreedor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.acreedor2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.activo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.activo2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.pasivo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.pasivo2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.perdida :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.perdida2 :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.ganancia :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.ganancia2 :"0" | currency}}</td>
                                        </tr>
                                        
                                        <tr v-show="totales != null && !comparativo">
                                            <td></td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.descripcion:"-"}}</td>
                                            <td style="text-align:right;" >{{(totales != null)?totales.totGeneral.debito  :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.credito :"0" | currency}}</td>
                                            <td style="text-align:right;">{{(totales != null)?totales.totGeneral.deudor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.acreedor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.activo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.pasivo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.perdida :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totGeneral.ganancia :"0" | currency}}</td>
                                        </tr>
                                        <tr v-show="totales != null && !comparativo">
                                            <td></td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.descripcion:"-"}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.debito :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.credito :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.deudor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.acreedor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.activo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.pasivo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.perdida :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totRes.ganancia :"0" | currency}}</td>
                                        </tr>
                                        <tr v-show="totales != null && !comparativo">
                                            <td></td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.descripcion:"-"}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.debito :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.credito :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.deudor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.acreedor :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.activo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.pasivo :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.perdida :"0" | currency}}</td>
                                            <td style="text-align:right">{{(totales != null)?totales.totSum.ganancia :"0" | currency}}</td>
                                        </tr>
                                    </tfoot>
                                    
                                </table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <Modal
            v-model="verModalDocumentos"
            :title="'Vista previa del documento'"
            :modal-style="{ 'min-width': '100px', 'max-width': '940px' }"
            :enableClose="false"
            @close="cerrarModal">
            <CRow>
                <CCol>
                    Generar PDF 
                    <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: 'Si',labelOff: 'No'}"
                        :checked="selectPdf"
                        @update:checked="cambiotipoDoc('pdf')"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol>
                    Generar CSV <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: 'Si',labelOff: 'No'}"
                        :checked="selectCsv"
                        @update:checked="cambiotipoDoc('csv')"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol v-show="selectPdf">
                    Generar con firmas <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: 'Si',labelOff: 'No'}"
                        :checked="generarConFirmas"
                        @update:checked="generarConFirmas = !generarConFirmas"
                    />
                </CCol>
                <CCol v-show="selectPdf">
                    Generar con Art. 100 <CSwitch
                        width="150px"
                        class="mt-1 mx-1 align-middle mb-2 switchGesto"
                        shape="pill"
                        variant="outline"
                        v-bind="{labelOn: 'Si',labelOff: 'No'}"
                        :checked="generarConArt100"
                        @update:checked="generarConArt100 = !generarConArt100"
                    />
                </CCol>
            </CRow>
            <CRow>
                <CCol sm="6" md="6" lg="6">
                    <CButton
                        v-show="documentoGenerado"
                        size="sm"
                        variant="ghost"
                        class="btnPrincipalVapp mb-1 mr-1"
                        @click="descargarDocumento"
                        :disabled="isLoadingDocumento"
                        v-c-tooltip.hover="{content: 'Descargar Documento generado',placement: 'rigth'}">
                            <CIcon size="sm" :content="$options.freeSet.cilCloudDownload" />
                            Descargar Documento
                    </CButton>
                </CCol>
            </CRow>
            <CargandoInfo :mostrarCarga="isLoadingDocumento" :mensajeInfo="'Generando...'" />
            
            <iframe
                v-show="!isLoadingDocumento && documentoGenerado"
                :src="docSeleccionado.linkView"
                width="100%"
                height="400"
                allow="autoplay"
                ></iframe>
                
            <hr>
            <div class="vm-footer">
                <CRow>
                    <CCol>
                        <CButton
                            size="sm"
                            v-show="selectPdf || selectCsv"
                            variant="ghost"
                            class="btnPrincipalVapp mb-1 mr-1 float-left"
                            @click="generarDocumento"
                            :disabled="isLoadingPdf">
                                <CIcon v-show="isLoadingPdf" :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                Generar Documento
                        </CButton>
                    </CCol>
                    <CCol>
                        <CButton
                        md="3"
                        class="btnPrincipalVapp float-right"
                        @click="cerrarModal"
                        >Cerrar</CButton
                        ></CCol
                    >
                </CRow>
            </div>
        </Modal>
        
    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    
    import cons from '@/const'
    import axios from "axios"
    
    import CargandoInfo from '@/views/utils/CargandoInfo'

    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'
    
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    import moment from 'moment'
    import { format, formatDistance, formatRelative, subDays } from 'date-fns'
    import es from 'date-fns/locale/es'
    window.locale = 'es'

    import { ColumnProps } from 'ant-design-vue/es/table/interface';

    
    import VisorDocumentos from "@/views/utils/VisorDocumentos";

    

    export default {
        name: 'BalanceTributario',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal,
            DatePicker,
            CargandoInfo
        },
        data () {
            return {
                urlBase: cons.port+"://" + cons.ipServer,
                
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                
                selectPeriodoActual: false,

                totalPagina: 300,
                scrollTabla: { y: 400 },
                
                aniosDesde: [],
                aniosHasta: [],

                mesDesdeSelected: null,
                mesHastaSelected: null,
                anioSelected: null,
                anio2Selected: null,

                empresasInfo: [],

                empSelected: null,
                
                comparativo: false,
                
                isLoadingEmpresasInfo: false,
                isLoadingBalance: false,
                isLoadingPdf: false,
                isLoadingCsv: false,
                pdfGenerado: false,
                csvGenerado: false,
                docPdf: null,
                docCsv: null,
                verDocumento: false,

                tipoDocSelected: null,


                verModalDocumentos: false,
                docSeleccionado: {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                },

                generarConFirmas: false,
                generarConArt100: false,
                selectPdf: false,
                selectCsv: false,

                isLoadingDocumento: false,
                documentoGenerado: false,

                ocultarCeros: true,
                
                colTotales: [
                    { title: '', scopedSlots: { customRender: 'codigo' }, width: '10%', align: 'left', ellipsis: true},
                    { title: '', scopedSlots:{ customRender: 'descripcion' }, width: '10%', align: 'right', ellipsis: false},
                    { title: '', scopedSlots: { customRender: 'debito' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'credito' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'deudor' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'acreedor' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'activo' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'pasivo' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'perdida' }, width: '10%', align: 'right', ellipsis: true},
                    { title: '', scopedSlots: { customRender: 'ganancia' }, width: '10%', align: 'right', ellipsis: true}
                    
                ],

                columnasBalance: [
                    { title: 'Código', scopedSlots: { customRender: 'codigo' }, width: '10%', align: 'left', ellipsis: true},
                    { title: 'Descripción', scopedSlots:{ customRender: 'descripcion' }, width: '10%', align: 'right', ellipsis: false},
                    { title: 'Debito', scopedSlots: { customRender: 'debito' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Credito', scopedSlots: { customRender: 'credito' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Deudor', scopedSlots: { customRender: 'deudor' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Acreedor', scopedSlots: { customRender: 'acreedor' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Activo', scopedSlots: { customRender: 'activo' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Pasivo', scopedSlots: { customRender: 'pasivo' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Perdida', scopedSlots: { customRender: 'perdida' }, width: '10%', align: 'right', ellipsis: true},
                    { title: 'Ganancia', scopedSlots: { customRender: 'ganancia' }, width: '10%', align: 'right', ellipsis: true}
                ],
                balance: [],
                dsTotales: [],
                totales: null,

                anioPeriodo: null,
                anioComparar: null,
                mesesPeriodo: null,
                langPicker: cons.langPicker,
                
            }
        },
        created: function(){},
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }

        },
        mounted(){
            this.obtenerSoloInfoEmpresas()
        },
        methods: {
            deshabilitarPeriodos: function(date, currentValue){
                let anio = format(date, "yyyy", {locale: es})
                let perNoEncontrado = true
                if(this.comparativo && this.anio2Selected != null){
                    let anioEncontrado = this.aniosDesde.find(periodo => periodo.anio == anio)
                    if (anioEncontrado !== undefined) {
                        if(this.anio2Selected.anio != anio){
                            perNoEncontrado = false
                        }
                    }
                }else{
                    let anioEncontrado = this.aniosDesde.find(periodo => periodo.anio == anio)
                    if (anioEncontrado !== undefined) {
                        perNoEncontrado = false
                    }
                }
                return perNoEncontrado

            },
            deshabilitarRangoMeses: function(date, currentValue){
                let mesAct = format(date, "M", {locale: es})
                let anio = format(date, "yyyy", {locale: es})
                let mesNoEncontrado = true
                if(this.anioPeriodo !== null){
                    if(format(this.anioPeriodo, "yyyy", {locale: es}) === anio){
                        let mesEncontrado = this.anioSelected.mesesDesde.find(mes => mes.valor == mesAct)
                        if(mesEncontrado != null){
                            mesNoEncontrado = false
                        }
                    }
                }
                return mesNoEncontrado
            },
            deshabilitarPeriodos2: function(date, currentValue){
                let anio = format(date, "yyyy", {locale: es})
                let perNoEncontrado = true
                if(this.aniosHasta !== null){
                    let anioEncontrado = this.aniosHasta.find(periodo => periodo.anio == anio)
                    if (anioEncontrado !== undefined && anio != this.anioSelected.anio) {
                        perNoEncontrado = false
                    }
                }
                return perNoEncontrado
            },
            selectRangoAnioComparar: function(){
                if(this.mesesPeriodo != null){
                    this.anio2Selected = this.aniosHasta.find(periodo => periodo.anio == format(this.anioComparar, "yyyy", {locale: es}))

                    console.log("this.anio2Selected", this.anio2Selected)
                    this.getBalance(this.anio2Selected)
                }
            },
            selectRangoAnio: function(){
                //this.anioPeriodo = new Date(primerPeriodo.anio, 1, 1)
                if(this.mesesPeriodo != null){
                    if(this.comparativo && this.anioSelected != null && this.anio2Selected != null){
                        if(this.anio2Selected.anio == format(this.anioPeriodo, "yyyy", {locale: es})){
                            this.anioPeriodo = new Date(this.anioSelected.anio, 1, 1)
                            this.mostrarNotificaciones(2, "El año seleccionado no puede ser igual o menor al comparado")
                            return
                        }
                    }
                    this.anioSelected = this.aniosDesde.find(periodo => periodo.anio == format(this.anioPeriodo, "yyyy", {locale: es}))
                    
                    this.mesDesdeSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == format(this.mesesPeriodo[0], "M", {locale: es}))
                    this.mesHastaSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == format(this.mesesPeriodo[1], "M", {locale: es}))
                    
                    
                    let f1 = new Date(this.anioSelected.anio, this.mesDesdeSelected.valor-1, 1)
                    let f2 = new Date(this.anioSelected.anio, this.mesHastaSelected.valor-1, 1)
                    this.mesesPeriodo = [f1, f2] 
                    this.getBalance(this.anioSelected)
                }
                
            },
            selectRangoMes: function(){
                this.mesDesdeSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == format(this.mesesPeriodo[0], "M", {locale: es}))
                this.mesHastaSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == format(this.mesesPeriodo[1], "M", {locale: es}))
                
                this.getBalance(this.anioSelected)
            },
            
            seleccionarEmpresa: function(){
                
                this.isLoadingBalance = true
                this.obtenerPeriodoEmpresa()
            },
            obtenerPeriodoEmpresa: function(){
                var url = this.urlBase + "/get_periodo_empresa_balance";
                
                this.aniosDesde = []
                this.aniosHasta = []

                
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idCliente": this.idCliente,
                        "idEmpresa": this.empSelected.idEmpresa
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.aniosDesde = result.data.aniosDesde
                    this.aniosHasta = result.data.aniosHasta
                    

                    if(this.selectPeriodoActual){
                        let fechaActual = new Date();
                        // Obtener el número del mes actual (0-11)
                        let mesActualNumero = fechaActual.getMonth()+1;
                        let anioActual = fechaActual.getFullYear();

                        
                        let primerPeriodo = this.aniosDesde[0]
                        let mesesPeriodo = primerPeriodo.mesesDesde
                        let primerMesPeriodo = mesesPeriodo[0]
                        let ultimoMesPeriodo = mesesPeriodo[mesesPeriodo.length-1]
                        this.anioSelected = primerPeriodo
                        if(this.anioSelected)
                        
                        this.mesDesdeSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == mesActualNumero)
                        this.mesHastaSelected = this.anioSelected.mesesDesde.find(mes => mes.valor == mesActualNumero)
                        
                        this.anioPeriodo = new Date(primerPeriodo.anio, 1, 1)
                        let f1 = new Date(primerPeriodo.anio, this.mesDesdeSelected.valor-1, 1)
                        let f2 = new Date(primerPeriodo.anio, this.mesHastaSelected.valor-1, 1)
                        this.mesesPeriodo = [f1, f2]
                        this.selectPeriodoActual = !this.selectPeriodoActual
                        
                        if(this.comparativo){
                            this.anio2Selected =  this.aniosHasta[1]
                            this.anioComparar = new Date(this.aniosHasta[1].anio,1,1)
                        }
                        this.getBalance()

                    } else{
                        
                        let primerPeriodo = this.aniosDesde[0]
                        this.anioPeriodo = new Date(primerPeriodo.anio, 1, 1)
                        this.anioSelected = primerPeriodo
                        let mesesPeriodo = primerPeriodo.mesesDesde
                        let primerMesPeriodo = mesesPeriodo[0]
                        let ultimoMesPeriodo = mesesPeriodo[mesesPeriodo.length-1]
                        
                        this.mesDesdeSelected = primerMesPeriodo
                        this.mesHastaSelected = ultimoMesPeriodo
                        
                        let f1 = new Date(primerPeriodo.anio, primerMesPeriodo.valor-1, 1)
                        let f2 = new Date(primerPeriodo.anio, ultimoMesPeriodo.valor-1, 1)
                        this.mesesPeriodo = [f1, f2] 
                        if(this.comparativo){
                            if(this.aniosHasta.length == 1 && this.aniosHasta[0].anio == this.anioSelected.anio){
                                this.mostrarNotificaciones(2, "Ups, la empresa seleccionada solo tiene un año de información para mostrar y es el mismo seleccionado")
                                this.comparativo = false
                            } else{
                                if(this.aniosHasta.length > 1){
                                    this.anio2Selected =  this.aniosHasta[1]
                                    this.anioComparar = new Date(this.aniosHasta[1].anio,1,1)
                                } else{
                                    this.anio2Selected =  this.aniosHasta[0]
                                    this.anioComparar = new Date(this.aniosHasta[0].anio,1,1)
                                }
                            }
                        }
                        this.getBalance()
                    }
                }, error => {})
                .finally(() => {});
            },
            getBalance: function(){
                if(this.anioSelected == null || this.mesDesdeSelected == null || this.mesHastaSelected == null || (this.comparativo && this.anio2Selected == null)){
                    this.mostrarNotificaciones(2, "Debe seleccionar las opciones")
                    this.isLoadingBalance = false
                    return
                }
                
                this.documentoGenerado = false
                this.selectPdf = false
                this.selectCsv = false
                this.generarConFirmas = false
                this.generarConArt100 = false

                this.csvGenerado = false

                this.isLoadingBalance = true

                /*if(this.comparativo){
                    this.anio2Selected = anio
                } else{
                    this.anioSelected = anio
                }*/

                this.balance = []

                var url = this.urlBase + "/get_balance_empresa";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "info": {
                            "idCliente": this.idCliente,
                            "idEmpresa": this.empSelected.idEmpresa,
                            "mesDesde": this.mesDesdeSelected.nombre,
                            "mesHasta": this.mesHastaSelected.nombre,
                            "anio": this.anioSelected.anio,
                            "comparativo": this.comparativo,
                            "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                            "ocultarCeros": this.ocultarCeros
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    if(this.comparativo){
                        this.scrollTabla.x = 1600
                        this.columnasBalance = [
                            { title: 'Código', scopedSlots: { customRender: 'codigo' }, width: '5%', align: 'left', ellipsis: false},
                            { title: 'Descripción', scopedSlots:{ customRender: 'descripcion' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Debito (${this.anioSelected.anio})`, scopedSlots: { customRender: 'debito' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Debito (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'debito2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Credito (${this.anioSelected.anio})`, scopedSlots: { customRender: 'credito' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Credito (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'credito2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Deudor (${this.anioSelected.anio})`, scopedSlots: { customRender: 'deudor' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Deudor (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'deudor2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Acreedor (${this.anioSelected.anio})`, scopedSlots: { customRender: 'acreedor' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Acreedor (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'acreedor2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Activo (${this.anioSelected.anio})`, scopedSlots: { customRender: 'activo' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Activo (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'activo2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Pasivo (${this.anioSelected.anio})`, scopedSlots: { customRender: 'pasivo' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Pasivo (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'pasivo2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Perdida (${this.anioSelected.anio})`, scopedSlots: { customRender: 'perdida' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Perdida (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'perdida2' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Ganancia (${this.anioSelected.anio})`, scopedSlots: { customRender: 'ganancia' }, width: '5%', align: 'right', ellipsis: false},
                            { title: `Ganancia (${this.anio2Selected.anio})`, scopedSlots: { customRender: 'ganancia2' }, width: '5%', align: 'right', ellipsis: false}
                        ]
                    }
                    this.totalPagina = result.data.balance.length
                    this.balance = result.data.balance;
                    this.totales = result.data.totales

                    this.colTotales = []
                    this.dsTotales = [this.totales.totGeneral, this.totales.totRes, this.totales.totSum]
                    
                    this.isLoadingBalance = false

                }, error => {
                    this.isLoadingBalance = false
                    this.$notification.error(
                        error.response.data.mensajeGeneral,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            ocultarCerosProcesar: function(){
                this.ocultarCeros = !this.ocultarCeros

                if(this.comparativo){
                    if(this.anio2Selected != null)
                        this.getBalance(this.anio2Selected)
                }
                else{
                    this.getBalance(this.anioSelected)
                }
            },
            cerrarModal: function () {
                this.verDocumento = false;
                this.verModalDocumentos = false;
            },
            cambiotipoDoc: function(tipoDoc){
                if(tipoDoc === 'pdf'){
                    this.selectPdf = !this.selectPdf
                    if(this.selectPdf)
                        this.selectCsv = false;
                }else if(tipoDoc === 'csv'){
                    this.selectCsv = !this.selectCsv;
                    if(this.selectCsv)
                        this.selectPdf = false;
                }
            },
            descargarDocumento: function(){
                /*var doc = null
                if(tipo === 'pdf'){
                    doc = this.docPdf
                }
                else if(tipo === 'csv'){
                    doc = this.docCsv
                }*/
                var fileLink = document.createElement("a");
                fileLink.href = this.docSeleccionado.link;
                fileLink.setAttribute("download", this.docSeleccionado.nombre);
                document.body.appendChild(fileLink);
                fileLink.click();
            },

            cambiarComparativo: function(comparativo){
                if(!comparativo){
                    delete this.scrollTabla.x
                    this.columnasBalance = [
                        { title: 'Código', scopedSlots: { customRender: 'codigo' }, width: '10%', align: 'left', ellipsis: true},
                        { title: 'Descripción', scopedSlots:{ customRender: 'descripcion' }, width: '10%', align: 'right', ellipsis: false},
                        { title: 'debito', scopedSlots: { customRender: 'debito' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'credito', scopedSlots: { customRender: 'credito' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'deudor', scopedSlots: { customRender: 'deudor' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'acreedor', scopedSlots: { customRender: 'acreedor' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'activo', scopedSlots: { customRender: 'activo' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'pasivo', scopedSlots: { customRender: 'pasivo' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'perdida', scopedSlots: { customRender: 'perdida' }, width: '10%', align: 'right', ellipsis: true},
                        { title: 'ganancia', scopedSlots: { customRender: 'ganancia' }, width: '10%', align: 'right', ellipsis: true}
                    ]
                   /*
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'saldoAnio2'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'analisisVert'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }
                    var encontrado = this.columnasEstadoResultado.indexOf(this.columnasEstadoResultado.find(columna => columna.scopedSlots.customRender == 'analisisHor'))
                    if ( encontrado !== -1 ) {
                        this.columnasEstadoResultado.splice(encontrado, 1)
                    }*/
                }
                if(this.empSelected != null && this.mesDesdeSelected != null && this.mesHastaSelected != null){
                    if(comparativo){
                        if(this.anio2Selected != null)
                            this.getBalance(this.anio2Selected)
                        else
                            this.pdfGenerado = false
                            this.csvGenerado = false
                    }
                    else{
                        this.getBalance(this.anioSelected)
                    }

                }
            },
            generarDocumento: function(){
                this.docSeleccionado = {
                    "link": "",
                    "nombre": "",
                    "linkView": ""
                }
                if(this.selectPdf){
                    this.generarPdf()
                }
                else if(this.selectCsv){
                    this.generarCsv()
                }
            },
            generarPdf: function(){
                this.isLoadingDocumento = true
                this.documentoGenerado = false

                this.isLoadingPdf = true
                
                this.pdfGenerado = false

                var url = this.urlBase + "/generar_pdf_balance";
                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idCliente": this.idCliente,
                        "idEmpresa": this.empSelected.idEmpresa,
                        "mesDesde": this.mesDesdeSelected.nombre,
                        "mesHasta": this.mesHastaSelected.nombre,
                        "anio": this.anioSelected.anio,
                        "comparativo": this.comparativo,
                        "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                        "agregarArt100": this.generarConArt100,
                        "agregarFirmas": this.generarConFirmas,
                        "ocultarCeros": this.ocultarCeros
                        
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    
                    this.isLoadingPdf = false
                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview'),
                    }

                    this.pdfGenerado = true
                    this.documentoGenerado = true
                    

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.documentoGenerado = false

                    this.isLoadingDocumento = false

                    this.isLoadingPdf = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },
            generarCsv: function(){
                this.isLoadingDocumento = true
                this.documentoGenerado = false

                this.isLoadingCsv = true
                this.csvGenerado = false

                var url = this.urlBase + "/generar_csv_balance";

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "idCliente": this.idCliente,
                        "idEmpresa": this.empSelected.idEmpresa,
                        "mesDesde": this.mesDesdeSelected.nombre,
                        "mesHasta": this.mesHastaSelected.nombre,
                        "anio": this.anioSelected.anio,
                        "comparativo": this.comparativo,
                        "anio2": (this.anio2Selected!=null)?this.anio2Selected.anio:null,
                        "ocultarCeros": this.ocultarCeros,
                        
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.isLoadingDocumento = false
                    this.documentoGenerado = true
                    
                    this.isLoadingCsv = false
                    
                    this.csvGenerado = true

                    this.docSeleccionado = {
                        "link": result.data.archivoGenerado.link,
                        "nombre": result.data.archivoGenerado.nombre,
                        "linkView": result.data.archivoGenerado.linkView.replace('view', 'preview')
                    }
                    

                    this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );

                }, error => {
                    this.isLoadingDocumento = false
                    this.documentoGenerado = false
                    this.isLoadingCsv = false
                    this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                    );
                });
            },

            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                var url = cons.port+"://" + cons.ipServer + "/get_empresas_info/CONTA";
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    //let mensaje = `Problema al obtener empresas , ${error}`
                    //this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            seleccionarPeriodoActual: function(){
                this.selectPeriodoActual = !this.selectPeriodoActual
                if(this.empSelected == null){
                    this.empSelected = this.empresasInfo[0]
                    this.isLoadingBalance = true
                    this.seleccionarEmpresa()
                } else {
                    this.isLoadingBalance = true
                    this.obtenerPeriodoEmpresa()
                }

            },

            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
        }
        
    }
</script>

<style>
    .tituloTarjeta{
        color: #29235C;
        margin-bottom: 0;
    }
    .ant-table-footer{
        padding: 0px;
        padding-right: 17px;
    }

    .switchGesto .c-switch-input:checked + .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-input:checked + .c-switch-slider::after {
        color: #29235C;
    }
    .switchGesto .c-switch-slider {
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::before {
        background-color: #29235C;
        border-color: #29235C;
    }
    .switchGesto .c-switch-slider::after {
        color: #461A3B;

    }


</style>